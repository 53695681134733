<template>

  <div>
    <div style="height: 0; opacity: 0; overflow: hidden; min-width:790px; width:790px; max-width:unset;">
      <b-card
        id="pdfGenerateDiv"
        ref="pdfGenerateDivRef"
        no-body
        class="invoice-preview-card"
      >
        <div
          v-for="pg in pdfTotalPage"
          :key="pg"
        >
          <div
            class="card__inner"
            style="height: 1080px;"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
                <div>
                  <div class="logo-wrapper">
                    <img
                      style="width:130px;"
                      src="/nirvana-memorial-garden-logo.png"
                      alt="Logo"
                    >
                  </div>
                </div>
                <div class="mt-md-0 mt-2 text-right">
                  <h4
                    class="invoice-title"
                    style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                  >
                    {{ caseID }}
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Action Taken By</span> {{ requesterName }}, {{ requesterDepartment }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  v-if="pg == 1"
                  cols="12"
                  xl="12"
                  class="p-0"
                >
                  <h4 style="font-size:14px; text-transform: uppercase; color: #000000; font-weight: 900; margin:0 0 20px">
                    Non-conforming material disposal & Corrective action
                  </h4>
                  <h6
                    class="mb-2"
                    style="font-size:14px; line-height:21px; color:#000000; margin:0 0 10px;"
                  >
                    Non-Conformance Details
                  </h6>
                  <b-table-simple
                    borderless
                    responsive
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Type of Non-Conformance
                        </b-th>
                        <b-td>{{ type }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Material
                        </b-th>
                        <b-td>{{ itemName }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Vendor
                        </b-th>
                        <b-td>{{ resolveVendorName() }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Quantity
                        </b-th>
                        <b-td>{{ quantity ? quantity : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Nature of Defect
                        </b-th>
                        <b-td>{{ natureOfDefect ? natureOfDefect : '-' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card-body>

            <hr
              class="invoice-spacing"
            >
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="12"
                  class="p-0"
                >
                  <h6
                    class="mb-2"
                    style="font-size:14px; line-height:17px; font-weight:500; color:#000000; margin:0 0 10px"
                  >
                    Proposed Solution
                  </h6>
                  <p
                    class="text-bold"
                    style="font-size:11px; font-weight:600; line-height:16px; margin:0;"
                  >
                    Proposed Action
                  </p>
                  <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 10px; display: block;">{{ proposedSolution }}</span>
                  <p
                    class="text-bold"
                    style="font-size:11px; font-weight:600; line-height:16px; margin:0;"
                  >
                    Remark
                  </p>
                  <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 10px; display: block;">{{ remarks ? remarks : '-' }}</span>
                  <p
                    class="text-bold"
                    style="font-size:11px; font-weight:600; line-height:16px; margin:0;"
                  >
                    Corrective Action By External Provider
                  </p>
                  <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 0px; display: block;">{{ correctiveAction ? correctiveAction : '-' }}</span>
                </b-col>
              </b-row>
            </b-card-body>

            <div
              v-if="pg == pdfTotalPage"
              style="position: relative; bottom: 0; width: 100%;"
            >
              <div :style="{ height: gapHeight+'px' }" />
              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0 footer__area">
                <b-row>

                  <!-- Col: Sales Persion -->
                  <b-col
                    v-for="(signatory, index) of signatoryAssigns"
                    :key="index"
                    cols="3"
                    md="3"
                    class="mt-md-1 mt-1"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">{{ loas[index].code }}</span>
                      <div class="sign-box">
                        <p>Pending Signature</p>
                      </div>
                      <p class="ml-75 semi-bold">
                        {{ resolveSignatoryName(signatory) }}
                      </p>
                      <p class="ml-75">
                        {{ resolveSignatoryDepartment(signatory) }}
                      </p>
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
          </div>
          <!-- <div
            v-if="pg != pdfTotalPage"
            class="html2pdf__page-break"
          /> -->
        </div>
      </b-card>
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <div
          class="card__inner"
          style="height: 1120px;"
        >
          <!-- Header -->
          <b-card-body
            id="main-header"
            class="invoice-padding pb-0"
          >

            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="logo-wrapper">
                <img
                  style="width:130px; height:auto;"
                  src="/nirvana-memorial-garden-logo.png"
                  alt="Logo"
                >
              </div>
              <div class="mt-md-0 mt-2 text-right">
                <h4
                  class="invoice-title"
                  style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                >
                  NCF-000048
                </h4>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Action Taken By</span> {{ requesterName }}, {{ requesterDepartment }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                  </p>
                </div>
              </div>
            </div>

            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >
          </b-card-body>

          <!-- Invoice Client & Payment Details -->
          <div id="content-div">
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  id="requester-header"
                  cols="12"
                  xl="12"
                  class="p-0"
                >
                  <h4 style="text-align: left; margin:0 0 20px; font-size:14px; line-height:18px; text-transform: uppercase; color: #000000; font-weight: 900;">
                    Quotation Comparison and Approval Form
                  </h4>
                  <h6
                    class="mb-2"
                    style="font-size: 14px; line-height: 21px; color: rgb(0, 0, 0); margin: 0px 0px 10px;"
                  >
                    Non-Conformance Details
                  </h6>
                  <b-table-simple
                    borderless
                    responsive
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Type of Non-Conformance
                        </b-th>
                        <b-td>{{ type }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Material
                        </b-th>
                        <b-td>{{ itemName }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Vendor
                        </b-th>
                        <b-td>{{ resolveVendorName() }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Quantity
                        </b-th>
                        <b-td>{{ quantity ? quantity : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Nature of Defect
                        </b-th>
                        <b-td>{{ natureOfDefect ? natureOfDefect : '-' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card-body>

            <hr
              class="invoice-spacing"
            >
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="12"
                  class="p-0"
                >
                  <h6
                    class="mb-2"
                    style="font-size: 14px; line-height: 17px; font-weight: 500; color: rgb(0, 0, 0); margin: 0px 0px 10px;"
                  >
                    Proposed Solution
                  </h6>
                  <p
                    class="text-bold"
                    style="font-size: 11px; font-weight: 600; line-height: 16px; margin: 0px;"
                  >
                    Proposed Action
                  </p>
                  <span style="font-size: 11px; font-weight: 400; line-height: 16px; margin: 0px 0px 10px; display: block;">{{ proposedSolution }}</span>
                  <p
                    class="text-bold"
                    style="font-size: 11px; font-weight: 600; line-height: 16px; margin: 0px;"
                  >
                    Remark
                  </p>
                  <span style="font-size: 11px; font-weight: 400; line-height: 16px; margin: 0px 0px 10px; display: block;">{{ remarks ? remarks : '-' }}</span>
                  <p
                    class="text-bold"
                    style="font-size: 11px; font-weight: 600; line-height: 16px; margin: 0px;"
                  >
                    Corrective Action By External Provider
                  </p>
                  <span style="font-size: 11px; font-weight: 400; line-height: 16px; margin: 0px 0px 10px; display: block;">{{ correctiveAction ? correctiveAction : '-' }}</span>
                </b-col>
              </b-row>
            </b-card-body>
          </div>

          <!-- Invoice Description: Total -->
          <b-card-body
            id="footer-div"
            class="invoice-padding pb-0 footer__area"
          >
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                v-for="(signatory, index) of signatoryAssigns"
                :key="index"
                cols="3"
                md="3"
                class="mt-md-1 mt-1"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">{{ loas[index].code }}</span>
                  <div class="sign-box">
                    <p>Pending Signature</p>
                  </div>
                  <p class="ml-75 semi-bold">
                    {{ resolveSignatoryName(signatory) }}
                  </p>
                  <p class="ml-75">
                    {{ resolveSignatoryDepartment(signatory) }}
                  </p>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </b-card>
    </div>
    <div v-show="ncfPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <b-row class="breadcrumbs-top">
                <b-col cols="12">
                  <h1 class="custom-header-title">
                    Preview Non-Conformance Form
                  </h1>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <section class="invoice-preview-wrapper">
        <b-row
          class="invoice-preview"
        >

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <div>
              <b-card
                class="mb-0"
              >
                <b-row class="invoice-preview">
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-left"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == 1"
                      @click="changeFileIndex(currentFileIndex - 1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="pt-25"
                        size="18"
                      />
                      <span class="align-middle">Prev</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-center"
                  >
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      split
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FileIcon"
                          size="18"
                          class="align-middle mr-1"
                        />
                        <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ attachments ? ((attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                      </template>
                      <b-dropdown-item @click="changeFileIndex(1)">
                        {{ caseID }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-for="(file, key) in attachments"
                        :key="key"
                        @click="changeFileIndex(key + 2)"
                      >
                        {{ file ? file.name : '' }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <p
                      v-show="currentFileIndex == 1"
                      class="mt-1"
                    >
                      Non-Conformance Form (NCF)
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == (attachments ? (attachments.length + 1) : 1)"
                      @click="changeFileIndex(currentFileIndex + 1)"
                    >
                      <span class="align-middle">Next</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="pt-25"
                        size="18"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <!-- <iframe
              id="docpdf"
              width="100%"
              height="1145"
              style="background: #9FB8D8; padding: 20px;"
            /> -->
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <div v-show="showAttachment">
                <iframe
                  v-show="showPDF"
                  id="attachmentpdf"
                  width="100%"
                  height="1145"
                />
                <b-img
                  v-show="!showPDF"
                  :src="imageSrc"
                  fluid-grow
                  alt="Fluid-grow image"
                />
              </div>
              <div v-show="!showAttachment">
                <iframe
                  id="docpdf"
                  width="100%"
                  height="1145"
                />
              </div>
            </b-card>
            <!-- <PDFJSViewer
              :path="path"
              :file-name="fileName"
            /> -->
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions"
          >
            <b-card>

              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="GridIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">Approval Required</span>
                  Ready to Submit?
                </h3>
              </template>

              <b-card-text class="mb-0">
                <span>
                  If the information on this Non-Conformance Form is accurate, please proceed to confirm to e-sign.
                </span>
                <br>
                <span>It will then be sent to the relevant parties for e-signing after.</span>
              </b-card-text>

              <b-form-checkbox
                v-model="secondAgree"
                value="accepted"
                unchecked-value=""
                class="custom-control-primary"
              >
                I confirm that all information is accurate on this NCF.
              </b-form-checkbox>

              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="secondAgree == ''"
                @click="finalConfirmation()"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Sign and Submit NCF</span>
              </b-button>

              <!-- Button: DOwnload -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                @click="enableEdit()"
              >
                Back to Editing
              </b-button>

              <!-- Button: Add Payment -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                @click="saveAsDraft()"
              >
                Save as Draft
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </div>

    <div v-show="!ncfPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <b-row class="breadcrumbs-top">
                <b-col cols="12">
                  <h1 class="custom-header-title">
                    Creating New Non-Conformance Form
                  </h1>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- Table Container Card -->
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="ncfCreateForm"
          #default="{invalid}"
        >
          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 1</span>
                Choose Purchase Order
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Related PR / PO"
                  vid="pr"
                  rules="required"
                >
                  <b-form-group
                    label="Related PR / PO*"
                    label-for="h-user-pr"
                    label-cols-md="4"
                    :state="(errors.length > 0 || prValidation) ? false : null"
                  >
                    <v-select
                      id="h-bookings-pr"
                      v-model="purchaseRequest"
                      label="purchaseRequest"
                      placeholder="Select an option"
                      :options="poOptions"
                      :clearable="false"
                    >
                      <!-- eslint-disable -->
                      <template #option="{ purchaseRequest }">
                        <span class="">{{ purchaseRequest.caseID }}</span>
                      </template>

                      <template #selected-option="{ purchaseRequest }">
                        <span class="">{{ purchaseRequest.caseID }}</span>
                      </template>
                      <!-- eslint-enable -->
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="prValidation"
                      class="text-danger"
                    >
                      {{ prError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 2</span>
                Type of Non-Conformance
              </h3>
            </template>
            <b-row>

              <b-col
                cols="12"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-radio-group
                    id="type"
                    v-model="type"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio w-100 text__left"
                  >
                    <b-form-radio
                      v-for="(option, index) in typeOptions"
                      :key="index"
                      :value="option.value"
                      class="mb-50 p-2"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <feather-icon
                        v-if="type === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="typeValidation"
                    class="text-danger"
                  >
                    {{ typeError }}
                  </small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 3</span>
                Item Information
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <b-form-group
                  label="Material*"
                  label-for="h-booking-itemName"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Material"
                    vid="itemName"
                    rules="required"
                  >
                    <b-form-input
                      id="h-booking-itemName"
                      v-model="itemName"
                      name="itemName"
                      placeholder="Product Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Nature of Defect"
                  label-for="h-booking-natureOfDefect"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nature of Defect"
                    vid="natureOfDefect"
                  >
                    <b-form-textarea
                      id="h-booking-natureOfDefect"
                      v-model="natureOfDefect"
                      trim
                      placeholder="Nature Of Defect"
                      :state="natureOfDefectValidation == true ? natureOfDefectValidation = false : null"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="natureOfDefectValidation"
                      class="text-danger"
                    >
                      {{ natureOfDefectError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="External Provider"
                  vid="External Provider"
                  rules="required"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-for="h-booking-vendor"
                    label="External Provider*"
                  >
                    <v-select
                      id="h-bookings-vendor"
                      v-model="vendor"
                      label="companyName"
                      placeholder="Select an option"
                      :options="vendorOptions"
                      :reduce="companyName => companyName._id"
                      :clearable="false"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <b-form-group
                  label="Quantity"
                  label-for="h-booking-quantity"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Quantity"
                    vid="quantity"
                    rules="float"
                  >
                    <b-form-input
                      id="h-booking-quantity"
                      v-model="quantity"
                      name="quantity"
                      placeholder="1"
                      data-vv-validate-on="blur"
                      @keypress="validateNumberInput"
                      @paste="validateNumberPaste"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 4</span>
                Proposed Solution
              </h3>
            </template>
            <b-row class="proposed__solution">

              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-radio-group
                    id="type"
                    v-model="proposedSolution"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio w-100 text__left"
                  >
                    <b-form-radio
                      v-for="(option, index) in solutionOptions"
                      :key="index"
                      :value="option.value"
                      class="mb-50 p-2"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <feather-icon
                        v-if="type === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="proposedSolutionValidation"
                    class="text-danger"
                  >
                    {{ proposedSolutionError }}
                  </small>
                </validation-provider>
              </b-col>
              <b-col cols="md-6">
                <b-form-group
                  label="Remarks"
                  label-for="h-booking-remarks"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Remarks"
                    vid="remarks"
                  >
                    <b-form-textarea
                      id="h-booking-remarks"
                      v-model="remarks"
                      trim
                      placeholder="Remarks"
                      :state="remarksValidation == true ? remarksValidation = false : null"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="remarksValidation"
                      class="text-danger"
                    >
                      {{ remarksError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="md-6">
                <b-form-group
                  label="Corrective Action By External Provider"
                  label-for="h-booking-correctiveAction"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Corrective Action"
                    vid="correctiveAction"
                  >
                    <b-form-textarea
                      id="h-booking-correctiveAction"
                      v-model="correctiveAction"
                      trim
                      placeholder="Corrective Action"
                      :state="correctiveActionValidation == true ? correctiveActionValidation = false : null"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="correctiveActionValidation"
                      class="text-danger"
                    >
                      {{ correctiveActionError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 5</span>
                Supporting Documents, Files and Images
              </h3>
            </template>
            <b-row>
              <b-col
                cols="12"
              >
                <vue-dropzone
                  id="event-images"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  :use-custom-slot="true"
                  @vdropzone-files-added="processFile"
                  @vdropzone-removed-file="fileRemoved"
                  @vdropzone-error="handleError"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drop files here or click to upload
                    </h3>
                    <div class="subtitle">
                      File should not bigger than 5 mb. JPEG, PNG, or PDF only
                    </div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mt-2"
                    >
                      Upload file
                    </b-button>
                  </div>
                </vue-dropzone>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
            class="signatoryOption_block"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">STEP 6</span>
                Signatories
              </h3>
            </template>
            <b-row>
              <b-col cols="12">
                <div class="info_item">
                  <feather-icon
                    icon="InfoIcon"
                    class=""
                    size="20"
                  />
                  <span class="align-middle">Please confirm the names of signatories required to sign on this form.</span>
                </div>
              </b-col>
              <b-col cols="md-6 no_of_sign">
                <validation-provider
                  #default="{ errors }"
                  name="Number of Signatory Required"
                  vid="signatoryOption"
                  rules="required"
                >
                  <b-form-group
                    label="Number of Signatory Required*"
                    label-for="h-activity-type-signatoryOption"
                    label-cols-md="5"
                    :state="(errors.length > 0 || signatoryOptionValidation) ? false : null"
                  >
                    <v-select
                      id="h-activity-type-signatoryOption"
                      v-model="signatoryOption"
                      label="title"
                      :options="signatoryOptions"
                      :reduce="title => title.code"
                      :clearable="false"
                      @input="changeSignatory()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="signatoryOptionValidation"
                      class="text-danger"
                    >
                      {{ signatoryOptionError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="md-6" />
            </b-row>
            <hr class="dividerHR">
            <div v-if="signatoryOption">
              <b-row
                v-for="(opt, key) in loas"
                :key="key"
              >
                <b-col
                  v-if="signatoryOption > key"
                  cols="md-6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name=""
                    :vid="'event-custom-field-' + key"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-md="5"
                      :label="opt.title"
                      :label-for="'event-custom-field-' + key"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <v-select
                        id="h-bookings-dutyOfficer"
                        v-model="signatoryAssigns[key]"
                        label="name"
                        placeholder="Select an option"
                        :disabled="key < 1"
                        :options="userOptions"
                        :reduce="name => name._id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </b-card>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :disabled="invalid"
                @click="saveAsDraft()"
              >
                Save as Draft
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                :disabled="invalid"
                @click="enablePreview()"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">Preview Before Sending</span>
              </b-button>
            </li>
          </b-nav>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, VBToggle,
  BFormRadioGroup, BFormRadio, BCardBody, BCardText, BImg,
  BTableSimple, BTbody, BTr, BTh, BTd, BFormCheckbox, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vue2Dropzone from 'vue2-dropzone'
import vSelect from 'vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store/index'
// import { jsPDF } from 'jspdf'
// import html2canvas from 'html2canvas'
import html2pdf from 'html2pdf.js'

// import PDFJSViewer from '../../components/PDFJSViewer.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BFormRadioGroup,
    BFormRadio,
    BCardBody,
    // BTableLite,
    BCardText,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    vSelect,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      ncfPreview: false,
      user: store.state.auth.userData,
      itemName: '',
      requesterName: store.state.auth.userData.name,
      requesterDepartment: store.state.auth.userData.department.length ? store.state.auth.userData.department[0].name : '',
      purchaseRequest: '',
      natureOfDefect: '',
      type: 'Incoming',
      saveType: 'preview',
      caseID: '',
      fileName: 'compressed.tracemonkey-pldi-09.pdf',
      path: '/lib/pdfjs/web/viewer.html',
      pdfData: '',
      signatoryOption: 2,
      signatoryOptionOld: 2,
      attachments: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      secondAgree: '',
      vendor: '',
      quantity: '',
      proposedSolution: 'Return to External Provider',
      remarks: '',
      correctiveAction: '',
      showPDF: true,
      showAttachment: false,
      selectedFileName: 'NCF-000048',
      currentFileIndex: 1,
      imageSrc: '',
      purchaseRequestError: 'Valid Justification / purchaseRequest is required',
      purchaseRequestValidation: false,
      natureOfDefectError: 'Valid natureOfDefect is required',
      natureOfDefectValidation: false,
      prValidation: false,
      prError: '',
      typeError: 'Valid Type of Request is required',
      typeValidation: false,
      proposedSolutionValidation: false,
      proposedSolutionError: '',
      remarksValidation: false,
      remarksError: '',
      correctiveActionValidation: false,
      correctiveActionError: '',
      userOptions: [],
      vendorOptions: [],
      poOptions: [],
      signatoryAssigns: [],
      signatoryAssignsError: 'Valid Signatory is required',
      signatoryAssignsValidation: false,
      signatoryOptionError: 'Valid Number of Signatory is required',
      signatoryOptionValidation: false,
      typeOptions: [
        { text: 'Incoming', value: 'Incoming' },
        { text: 'Work-in-progress (WIP)', value: 'Work-in-progress (WIP)' },
      ],
      solutionOptions: [
        { text: 'Return to External Provider', value: 'Return to External Provider' },
        { text: 'Rework', value: 'Rework' },
        { text: 'Use as it is', value: 'Use as it is' },
        { text: 'Reject', value: 'Reject' },
      ],
      signatoryOptions: [
        { title: '2', code: '2' },
        { title: '3', code: '3' },
      ],
      loas: [
        { title: '1. Action Taken By', code: '1. Action Taken By' },
        { title: '2. Approved By', code: '2. Approved By' },
        { title: '3. Verified By', code: '3. Verified By' },
      ],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },

      // validation rules
      required,
    }
  },

  // mounted() {
  //   const recaptchaScript = document.createElement('script')
  //   recaptchaScript.setAttribute('src', '/Montserrat-Regular-normal.js')
  //   document.head.appendChild(recaptchaScript)
  // },

  created() {
    this.$http.get('purchase/ncf/create/extra-options')
      .then(response => {
        this.userOptions = response.data.userOptions
        this.vendorOptions = response.data.vendorOptions
        this.poOptions = response.data.poOptions
        this.caseID = response.data.caseID

        this.signatoryAssigns.push(this.user._id)
        if (this.$route.query.po) {
          const pRequest = this.poOptions.find(o => o.purchaseRequest._id === this.$route.query.po)
          if (pRequest) {
            this.purchaseRequest = pRequest
            this.vendor = pRequest.vendor
          }
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  beforeRouteLeave(to, from, next) {
    const bcLength = store.state.breadcrumb.breadcrumbs.length
    if (bcLength > 2) {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
    }
    next()
  },
  methods: {
    saveAsDraft() {
      this.saveType = 'draft'
      this.submitForm()
    },
    // eslint-disable-next-line consistent-return
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    async changeSignatory() {
      // eslint-disable-next-line radix
      this.signatoryOption = parseInt(this.signatoryOption)
      if (this.signatoryOptionOld > this.signatoryOption) {
        await this.$nextTick()
        this.$swal({
          title: 'Are You Sure?',
          html: 'You are changing the number of signatory.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure.",
          customClass: {
            confirmButton: 'btn btn-danger ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.signatoryOptionOld = this.signatoryOption
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            } else {
              this.signatoryOption = this.signatoryOptionOld
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            }
          })
      } else {
        this.signatoryOptionOld = this.signatoryOption
        // eslint-disable-next-line radix
        const intOption = parseInt(this.signatoryOption)
        if (this.signatoryAssigns.length > intOption) {
          this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
        }
      }
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = this.caseID
        this.showAttachment = false
      } else {
        this.selectedFileName = this.attachments[key - 2].name
        if (this.attachments[key - 2].type.includes('pdf')) {
          const base64Raw = this.attachments[key - 2].data.split(',')[1]
          const pdfDataJS = this.base64ToUint8Array(base64Raw)
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          pdfViewerFrame2.onload = () => {
            pdfViewerFrame2.contentWindow.PDFViewerApplication.open(pdfDataJS)
          }
          pdfViewerFrame2.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          this.showPDF = true
        } else {
          this.imageSrc = this.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    async enablePreview() {
      const newMeta = {
        active: true,
        text: 'Preview',
      }
      store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
      store.commit('appConfig/UPDATE_LOADER', true)
      const vm = this
      await this.calculateTotalPage()

      // start of pdf using html2PDF package
      const element = document.getElementById('pdfGenerateDiv')
      const opt = {
        margin: 1,
        filename: 'myfile.pdf',
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page-section' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 2,
          dpi: 192,
          // windowWidth: 1024,
          letterRendering: true,
        },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'p' },
      }

      html2pdf().set(opt).from(element).toPdf()
        .get('pdf')
        .then(pdf => {
          vm.pdfData = pdf.output('datauristring')
          const base64Raw = vm.pdfData.split(',')[1]
          const pdfDataJS = vm.base64ToUint8Array(base64Raw)
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          vm.currentFileIndex = 1
          vm.selectedFileName = vm.caseID
          vm.showAttachment = false
          vm.ncfPreview = true
          window.scrollTo(0, 0)
          store.commit('appConfig/UPDATE_LOADER', false)
        })
      // end of pdf using html2PDF package
    },
    enableEdit() {
      // const prevData = store.state.breadcrumb.breadcrumbs
      // prevData.pop()
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
      this.ncfPreview = false
    },
    resolveNextSignatoryName() {
      const user = this.userOptions.find(o => o._id === this.signatoryAssigns[1])
      if (user) {
        return user.name
      }

      return ''
    },
    resolveSignatoryName(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.name
      }

      return ''
    },
    resolveVendorName() {
      const vendor = this.vendorOptions.find(o => o._id === this.vendor)
      if (vendor) {
        return vendor.companyName
      }

      return ''
    },
    resolveSignatoryDepartment(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.department.length ? user.department[0].name : ''
      }

      return ''
    },
    processFile(file) {
      const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 5 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    async calculateTotalPage() {
      this.pdfTotalPage = 0
      const logoHeight = document.getElementById('main-header').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      const dHeight = 1080
      this.pdfTotalPage = 1
      const finalDataHeight = document.getElementById('content-div').offsetHeight
      this.gapHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
      if (finalDataHeight >= (dHeight - (logoHeight + footerHeight))) {
        this.pdfTotalPage = 2
        this.gapHeight = dHeight - (logoHeight + footerHeight)
      }
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    finalConfirmation() {
      this.$swal({
        title: 'Submit this NCF?',
        html: `You are proceeding to sign this NCF. The NCF will then be ready for ${this.resolveNextSignatoryName()} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.submitForm()
          }
        })
    },
    submitForm() {
      this.$refs.ncfCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('vendor', this.vendor)
          formData.append('url', window.location.origin)

          formData.append('purchaseOrder', this.purchaseRequest._id)

          formData.append('purchaseRequest', this.purchaseRequest.purchaseRequest._id)
          formData.append('type', this.type)
          formData.append('itemName', this.itemName)
          formData.append('natureOfDefect', this.natureOfDefect)
          formData.append('quantity', this.quantity)
          formData.append('proposedSolution', this.proposedSolution)
          formData.append('remarks', this.remarks)
          formData.append('correctiveAction', this.correctiveAction)
          formData.append('signatoryAssigns', JSON.stringify(this.signatoryAssigns))
          formData.append('status', this.saveType)
          formData.append('attachments', JSON.stringify(this.attachments))

          this.$http.post('purchase/ncf/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                if (this.saveType === 'preview') {
                  this.$swal({
                    title: 'NCF Submitted for Approval',
                    html: `NCF has been created, and will now be sent to the next assigned signatory (${this.resolveNextSignatoryName()}) for signing.`,
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/success.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                    .then(result => {
                      if (result.value) {
                        this.$router.push({ name: 'purchasing-ncf-show', params: { id: response.data.data._id } })
                      }
                    })
                } else {
                  this.$swal({
                    title: 'Draft Saved',
                    html: 'You can continue editing this NCF at a later time before sending it out for approval.',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                    .then(result => {
                      if (result.value) {
                        this.$router.push({ name: 'purchasing-ncf-show', params: { id: response.data.data._id } })
                      }
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'deliveryDate') {
                    this.deliveryDateError = validationError.msg
                    this.deliveryDateValidation = true
                  } else if (validationError.param === 'deliverTo') {
                    this.deliverToError = validationError.msg
                    this.deliverToValidation = true
                  } else if (validationError.param === 'purchaseRequest') {
                    this.purchaseRequestError = validationError.msg
                    this.purchaseRequestValidation = true
                  } else if (validationError.param === 'natureOfDefect') {
                    this.natureOfDefectError = validationError.msg
                    this.natureOfDefectValidation = true
                  } else if (validationError.param === 'type') {
                    this.typeError = validationError.msg
                    this.typeValidation = true
                  } else if (validationError.param === 'certificates') {
                    this.certificatesError = validationError.msg
                    this.certificatesValidation = true
                  } else if (validationError.param === 'signatoryOption') {
                    this.signatoryOptionError = validationError.msg
                    this.signatoryOptionValidation = true
                  } else if (validationError.param === 'company') {
                    this.companyError = validationError.msg
                    this.companyValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
  .bookingCounter {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 2px;

    background: #fff;
    border: 1px solid #666;
    color: #666;
    text-align: center;

    font-size: 10px;
  }

  .vs__dropdown-option--disabled {
    background: #ededed;
    color: #000000;
  }
  .break-page {
    page-break-after: always;
  }
</style>
